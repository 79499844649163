<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <div class="card card-custom">
      <div class="card-header border-0 pt-6 pb-0">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder">{{ $t('Service Settings') }} </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t('Update your company service settings')
          }}</span>
        </div>
        <div class="card-toolbar">
          <button class="btn btn-success mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
            @click.prevent="handleSubmit(save)">
            {{ $t("Save Changes") }}
          </button>
        </div>
      </div>
      <div class="card-body detail">
        <div v-for="dataset in settings" :key="dataset.id">
          <div v-if="dataset.type == 'integer'">
            <validation-provider v-slot="{ errors }" :name="$t(dataset.name)" :vid="dataset.name" rules="required">
              <EuroInput v-model="dataset.value" :label="$t(dataset.name)" :error-messages="errors" type="number"
                :min="dataset.min" :max="dataset.max">
              </EuroInput>
            </validation-provider>
          </div>
          <div v-else-if="dataset.type == 'number'">
            <validation-provider v-slot="{ errors }" :name="$t(dataset.name)" :vid="dataset.name" rules="required">
              <EuroInput v-model="dataset.value" :label="$t(dataset.name)" :error-messages="errors" type="number"
                :min="dataset.min" :max="dataset.max" step="0.01">
              </EuroInput>
            </validation-provider>
          </div>
          <div v-else-if="dataset.type == 'boolean'" class="form-group row">
            <label class="col-6 col-form-label">{{ $t(dataset.name) }}</label>
            <div class="col-6">
              <span class="switch switch-sm mt-2" style="float: right;">
                <label>
                  <input v-model="dataset.value" type="checkbox" :name="dataset.name" />
                  <span></span>
                </label>
              </span>
            </div>
          </div>
          <div class="separator separator-dashed mb-7"></div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CompanyService from "@/core/services/company/company.service";
import { dangerToast, successToast } from "@/core/helpers";
import { mapActions } from "vuex";

export default {
  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      settings: [],
      setting: {
        id: null,
        name: null,
        default: null,
        value: null,
        type: null,
        min: 0,
        max: null
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person"), route: { name: "manage-legal-person" } },
      { title: this.$t("Settings") },
    ]);

    // Update setting
    this.settings = []
    this.legalPerson.focussetting_set.forEach(element => {
      const setting = { ...this.setting }
      setting.id = element.id
      setting.name = element.setting_type.name
      setting.default = element.setting_type.default_value
      setting.value = element.value
      setting.type = element.setting_type.validators.type
      if (['number', 'integer'].includes(setting.type)) {
        setting.min = element.setting_type.validators.minimum
        setting.max = element.setting_type.validators.maximum
      }
      this.settings.push(setting)
    });
  },
  methods: {
    ...mapActions('user', ['setFocusSettings']),
    async save() {
      this.isSaving = true;
      const body = {
        focussetting_set: this.settings.map((el) => {
          if (['number', 'integer'].includes(el.type)) {
            return { id: el.id, value: Number(el.value) }
          } else {
            return { id: el.id, value: el.value }
          }
        })
      }
      await CompanyService.update(body, this.legalPerson.id).then(() => {
        // Update focus setting on vuex
        return CompanyService.getOne(this.legalPerson.id, null, 'focussetting_set').then((response) => {
          this.setFocusSettings(response.data.focussetting_set)
        })
      }).then(() => {
        successToast(this.$t("Settings updated"));
      }).catch((err) => {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          dangerToast(err?.response?.data?.detail);
        }
      }).finally(() => {
        this.isSaving = false;
      });
    }
  }
}
</script>