var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          staticClass: "card card-custom"
        }, [_c('div', {
          staticClass: "card-header border-0 pt-6 pb-0"
        }, [_c('div', {
          staticClass: "card-title align-items-start flex-column"
        }, [_c('h3', {
          staticClass: "card-label font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.$t('Service Settings')) + " ")]), _c('span', {
          staticClass: "text-muted font-weight-bold font-size-sm mt-1"
        }, [_vm._v(_vm._s(_vm.$t('Update your company service settings')))])]), _c('div', {
          staticClass: "card-toolbar"
        }, [_c('button', {
          staticClass: "btn btn-success mr-2",
          class: {
            'spinner spinner-light spinner-right': _vm.isSaving
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return handleSubmit(_vm.save);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Save Changes")) + " ")])])]), _c('div', {
          staticClass: "card-body detail"
        }, _vm._l(_vm.settings, function (dataset) {
          return _c('div', {
            key: dataset.id
          }, [dataset.type == 'integer' ? _c('div', [_c('validation-provider', {
            attrs: {
              "name": _vm.$t(dataset.name),
              "vid": dataset.name,
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref2) {
                var errors = _ref2.errors;
                return [_c('EuroInput', {
                  attrs: {
                    "label": _vm.$t(dataset.name),
                    "error-messages": errors,
                    "type": "number",
                    "min": dataset.min,
                    "max": dataset.max
                  },
                  model: {
                    value: dataset.value,
                    callback: function callback($$v) {
                      _vm.$set(dataset, "value", $$v);
                    },
                    expression: "dataset.value"
                  }
                })];
              }
            }], null, true)
          })], 1) : dataset.type == 'number' ? _c('div', [_c('validation-provider', {
            attrs: {
              "name": _vm.$t(dataset.name),
              "vid": dataset.name,
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref3) {
                var errors = _ref3.errors;
                return [_c('EuroInput', {
                  attrs: {
                    "label": _vm.$t(dataset.name),
                    "error-messages": errors,
                    "type": "number",
                    "min": dataset.min,
                    "max": dataset.max,
                    "step": "0.01"
                  },
                  model: {
                    value: dataset.value,
                    callback: function callback($$v) {
                      _vm.$set(dataset, "value", $$v);
                    },
                    expression: "dataset.value"
                  }
                })];
              }
            }], null, true)
          })], 1) : dataset.type == 'boolean' ? _c('div', {
            staticClass: "form-group row"
          }, [_c('label', {
            staticClass: "col-6 col-form-label"
          }, [_vm._v(_vm._s(_vm.$t(dataset.name)))]), _c('div', {
            staticClass: "col-6"
          }, [_c('span', {
            staticClass: "switch switch-sm mt-2",
            staticStyle: {
              "float": "right"
            }
          }, [_c('label', [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: dataset.value,
              expression: "dataset.value"
            }],
            attrs: {
              "type": "checkbox",
              "name": dataset.name
            },
            domProps: {
              "checked": Array.isArray(dataset.value) ? _vm._i(dataset.value, null) > -1 : dataset.value
            },
            on: {
              "change": function change($event) {
                var $$a = dataset.value,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(dataset, "value", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(dataset, "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(dataset, "value", $$c);
                }
              }
            }
          }), _c('span')])])])]) : _vm._e(), _c('div', {
            staticClass: "separator separator-dashed mb-7"
          })]);
        }), 0)])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }